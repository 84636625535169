@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/Apercu-Pro-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url("../fonts/Apercu-Pro-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url("../fonts/Apercu-Pro-Bold.otf") format("opentype");
}

header {
  @apply relative;
  &::before {
    @apply absolute bottom-0 -left-[25%] -right-[25%] sm:-left-[10%] sm:-right-[10%] 2xl:-left-[25%] 2xl:-right-[25%] 3xl:-left-[50%] 3xl:-right-[50%] bg-orange-500 -z-10;
    border-radius: 0 0 50% 50%;
    content: "";
    padding-bottom: 100%;
  }
}

footer {
  @apply relative;
  &::before {
    content: "";
    @apply absolute bg-circle-orange-500 bg-repeat-x;
    top: -102px;
    left: -103px;
    right: 0;
    height: 103px;
  }
  &::after {
    content: "";
    @apply absolute bg-circle-orange-100 bg-repeat-x z-10;
    bottom: 0;
    left: -103px;
    right: 0;
    height: 103px;
  }
}

.footer-bg {
  @apply relative z-10;
  height: 203px;
  &::before {
    content: "";
    @apply absolute bg-circle-orange-100 bg-repeat-x;
    top: 0;
    left: -103px;
    right: 0;
    height: 203px;
  }
  &::after {
    content: "";
    @apply absolute bg-circle-orange-500 bg-repeat-x z-10;
    top: 0;
    left: 0;
    right: 0;
    height: 203px;
    transform: translateY(50%);
  }
}

.bg-offset {
  &::before {
    @apply absolute bg-pink-300 rounded-2xl;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translate(24px, 24px);
  }
}

.contact-container {
  @apply relative;
  &::before {
    @apply absolute bg-beige-300 p-6 rounded-[80px] md:rounded-[160px] -z-10;
    content: "";
    top: 8px;
    left: 0;
    right: 0;
    bottom: -8px;
  }
}

.menu-container {
  @apply relative z-10;
  &::before {
    @apply absolute bg-orange-300 rounded-full -z-10;
    content: "";
    top: 2px;
    left: 0;
    right: 0;
    bottom: -2px;
  }
}
